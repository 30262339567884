@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_box-shadow-style";
@import "../../../Less/Mixins/_comment-style";
@import "../../../Less/_brand-colors";

.theme-account-overview-drawer {
  position: fixed;
  right: 0;
  top: 20vh;
  z-index: 1000;
  .box-shadow(clippy);

  body.page-app {
    top: 35vh;
  }

  .theme-account-overview-float-wrapper {
    .theme-badge {
      background: none !important;
      left: calc(50% + 0.3rem);
      padding: 0 !important;
      position: absolute;
      top: -0.3rem;
      transform: translateY(30%);
      z-index: 101;

      .ant-badge-count {
        background: @primary !important;
        line-height: 2;

        &[title="0"] {
          background: @text-color !important;
          line-height: @badge-line-height-0;
        }
      }
    }
  }

  .theme-button {
    align-items: center !important;
    border-radius: 0.3rem 0 0 0.3rem !important;
    display: flex !important;
    flex-direction: column;
    padding: 0.7rem 1.2rem !important;

    > span {
      display: inline-block;
      line-height: 1.1;
      margin-top: 0.4rem;
      max-width: 6rem;
      white-space: pre-wrap;
      word-break: break-word;
    }

    @media @max480 {
      min-width: 5.5rem;
      padding: 0.5rem 0.5rem 0.5rem 1rem !important;

      svg {
        flex-basis: 100%;
        max-height: 30px;
      }

      > span {
        font-size: smaller;
      }
    }
  }
}

.theme-account-overview-comment {
  .comment-style();

  margin-bottom: @section-gap;

  .ant-image {
    text-align: center;
    width: 100%;

    .ant-image-img {
      margin-left: -3rem;
      max-width: 10rem;
    }
  }
}

.theme-panel-wrapper.slide-out h4 {
  font-size: @heading-5-size !important;
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;