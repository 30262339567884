@font-face {
  font-display: swap;
  font-family: 'Avenir Next LT Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Regular.eot');
  src:
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Regular.woff2') format('woff2'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Regular.woff') format('woff'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Regular.ttf') format('truetype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Avenir Next LT Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Medium.eot');
  src:
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Medium.woff2') format('woff2'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Medium.woff') format('woff'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Medium.ttf') format('truetype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Medium.svg#AvenirNextLTPro-Medium') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Avenir Next LT Demi Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Demi.eot');
  src:
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Demi.eot?#iefix') format('embedded-opentype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Demi.woff2') format('woff2'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Demi.woff') format('woff'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Demi.ttf') format('truetype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Avenir Next LT Regular Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Bold.eot');
  src:
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Bold.woff2') format('woff2'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Bold.woff') format('woff'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Bold.ttf') format('truetype'),
    url('../../src/Assets/Fonts/AvenirNext/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
}


/* Avenir LT */

@font-face {
  font-display: swap;
  font-family: 'Avenir LT Light';
  font-style: normal;
  font-weight: normal;
  src: url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Light.eot');
  src:
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Light.eot?#iefix') format('embedded-opentype'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Light.woff2') format('woff2'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Light.woff') format('woff'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Light.ttf') format('truetype'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Light.svg#AvenirLT-Light') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Avenir LT Black';
  font-style: normal;
  font-weight: normal;
  src: url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Black.eot');
  src:
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Black.eot?#iefix') format('embedded-opentype'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Black.woff2') format('woff2'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Black.woff') format('woff'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Black.ttf') format('truetype'),
    url('../../src/Assets/Fonts/AvenirLT/AvenirLT-Black.svg#AvenirLT-Black') format('svg');
}
@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;