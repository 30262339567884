@import "../../../Less/Mixins/_text-style";
@import "../../../Less/Mixins/_box-shadow-style";
@import "../../../Less/Mixins/_alignfull";
@import "../../../Less/Mixins/_container";
@import "../../../Less/_default-variables";

.theme-header {
  margin-bottom: @section-gap;

  &.apm {

    .ant-image-img {
      max-height: 4rem;
      padding: @gap-sm 0;
      transition-duration: 0.1s;
      transition-timing-function: ease;
    }

    .theme-menu > .ant-menu-submenu .ant-menu-submenu-title::after {
      color: @white;
    }

    &.sticky {
      .theme-top-menus .ant-image .ant-image-img {
        max-height: 3rem;
        padding: @gap-sm 0;
      }
    }
    &.desktop {
      .theme-menu {
        background: transparent !important;
      }

      .theme-top-menus {
        background: @white;

        .ant-menu-item,
        .ant-menu-submenu {
          > span a {
            color: @text-color !important;
          }

          &:hover {
            background: transparent !important;

            a {
              color: @grey-base !important;
            }
          }
        }

        .ant-menu-item.ant-menu-item-selected > span a {
          color: @white !important;
        }
      }
      .theme-role-menus {
        background: linear-gradient(-45deg, #41c7b4 0%, @tertiary 39%, @primary 100%);

        .ant-menu-item,
        .ant-menu-submenu {
          color: @white !important;

          &:hover,
          &.ant-menu-item-selected,
          &.ant-menu-submenu-active {

            .ant-menu-submenu-title:hover {
              color: @white;
            }

            &::after {
              border-color: @white;
            }
          }
        }
      }
    }

    &.mobile {
      .theme-mobile-menus {
        background: @white;
      }
    }
  }

  &.sticky {
    position: sticky;
    top: 0;
    transition: position 5s ease-in;
    width: 100%;
    z-index: 999;

    .theme-role-menus .theme-menu {
      line-height: 2rem;
    }

    .theme-top-menus {

      .theme-menu {
        line-height: 2rem;
      }

      .ant-image {
        min-height: 3rem;

        .ant-image-img {
          max-height: 2rem;
        }
      }
    }
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;