@import "../../../Less/_brand-colors";
@import "../../../Less/_typography";
@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_loan-state-colors";

.theme-loan-state {
  align-items: center;
  color: @white;
  display: flex;
  font-family: @heavy-font;
  font-size: @font-size-xxs;
  height: 75px;
  justify-content: center;
  margin-right: @gap-base;
  min-width: 75px;
  text-align: center;
  text-transform: capitalize;
  width: 75px;

  &::after {
    background-color: @grey-bg-color;
    content: "";
    display: inline-block;
    height: 100%;
    left: -5%;
    margin: auto;
    position: absolute;
    top: 0;
    width: 110%;
    z-index: -1;
  }
}

each(.loan-state-colors(), {
  .loan--state__@{key}::after {
    background: @value;
  }
});

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;