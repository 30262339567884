@import "../../../Less/_default-variables";

.theme-button-group {
  margin-top: @gap-medium;

  .theme-button + .theme-button {
    margin-left: 1rem;

    @media @max480 {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    flex-wrap: wrap;
  }

  &.set-3-style {
    display: flex;

    @media @max480 {
      .theme-button + .theme-button {
        margin-left: 0;
        margin-top: 0.5rem;
      }

      .ant-form-item-control-input-content {
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      .mr-auto {
        margin-right: unset !important;
      }
    }

    .ant-drawer-footer & {
      margin: @gap-sm 0 !important;
      padding-bottom: 0 !important;

      @media @max480 {
        display: inline-flex;
        flex-direction: column;
        .theme-button {
          margin-left: 0 !important;
          margin-right: 0 !important;

          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  @media @max990 {
    .ant-drawer-body & {
      &.set-3-style {
        margin-top: auto;
        .theme-button {
          margin-left: 0 !important;
          margin-right: 0 !important;

          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  &.theme-button-group-center {
    .ant-form-item-control-input-content {
      justify-content: center;
    }
  }

  &.theme-button-group-right {
    .ant-form-item-control-input-content {
      justify-content: flex-end;
    }
  }

  &.theme-button-group-bottom-right {
    margin-top: @section-gap !important;
    padding-bottom: @section-gap !important;

    .ant-form-item-control-input-content {
      justify-content: flex-end;
    }

    &.set-2-style {
      @media @max480 {
        .theme-button + .theme-button {
          margin-left: 0.5rem;
          margin-top: 0;
        }
      }
    }
  }

  &.theme-button-group-vertical {
    .ant-form-item-control-input-content {
      flex-direction: column;
      padding: 0 1rem;
    }
  }

  &.theme-button-group-space-between {
    .ant-form-item-control-input-content {
      justify-content: space-between;

      > div {
        display: flex;
      }

      @media @max480 {
        justify-content: flex-start;

        > div {
          flex-direction: column;

          + div {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  + .theme-input-group {
    margin-top: ~"calc(-@{formItem-m-mobile} * 2)" !important;

    @media @min769 {
      margin-top: ~"calc(-@{formItem-m} * 2)" !important;
    }
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;