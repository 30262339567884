@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";
@import "../src/Less/_brand-colors";
@import "../src/Less/Mixins/_container";
@import "../src/Less/_typography";
@import "../src/Less/_global.less";

html {
  scrollbar-color: @grey-base transparent;
  scrollbar-width: thin;
}
/* Style the scrollbar background */
::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}
/* Style the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: @grey-base;
  border-radius: 5px;
}
body,
body > .region {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: clip;
}

#reactApp {
  height: 100%;
}

body {
  color: @text-color !important;
  font-family: @text-font;

  &.page-app-login {
    .region-page-bottom {
      height: unset !important;
    }
  }

  &.html {
    padding-top: 0;
  }

  #react-app {
    .react-top-header {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }
}

a {
  color: @link-color;

  &:hover {
    color: @link-hover-color;
  }
}

hr {
  border: 1px solid @border-color;
  margin: 2rem 0;
}

.sb-show-main {
  padding: 0 !important;
  .container();

  #root {
    height: 100%;
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;