@import "../../../Less/_default-variables";
@import "../../../Less/_brand-colors";
@import "../../../Less/_typography";

.theme-tabs {
  &.ant-tabs-card {
    .ant-tabs-nav {
      margin-bottom: @gap-medium;

      &::before {
        display: none;
      }
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background: @white;
        border: none;
        color: @heading-text-color;
        font-family: @heading-font;
        font-size: @font-size-stand;
        position: relative;
        text-transform: uppercase;

        &::after {
          background: @tab-border;
          border-radius: 0 0.5rem 0 0;
          bottom: 0;
          content: "";
          height: 0.2rem;
          left: 0;
          position: absolute;
          width: 100%;
        }

        &:not(:last-of-type) {
          margin-right: 1rem !important;
        }

        &.ant-tabs-tab-active {
          background: @secondary;
          border-radius: 0 1rem 0 0;

          &::after {
            display: none;
          }

          .ant-tabs-tab-btn {
            color: @white !important;
          }
        }
      }

      @media @max480 {
        flex-direction: column;
        width: 100%;
      }
    }
    .ant-tabs-extra-content {
      .theme-button:not(.ant-btn-circle) {
        border: none !important;
      }
    }

    .no-result {
      background: @grey-bg-color;
      padding: 2rem;
      text-align: center;
    }
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;