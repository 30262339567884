@import "../../../Less/Mixins/_input-style.less";
@import "../../../Less/Mixins/_faicon";

.theme-select,
.ant-select {
  background: @grey-bg-color;
  border-radius: @border-radius-base;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  width: 100% !important;

  &.ant-select-multiple {
    .ant-select-selection-item {
      background: @grey-dark;
      border-radius: @gap-medium;
      padding-left: @gap-sm;
      padding-right: @gap-sm;

      > span {
        color: @white !important;
      }
    }
  }

  .ant-select-selector {
    align-items: center;
    background: @grey-bg-color !important;
    border: none !important;
    border-radius: @border-radius-base !important;
    display: flex !important;
    min-height: calc((@font-size-stand * 1.5) + 1.3rem);
  }

  input {
    background: transparent !important;
    color: @text-color !important;
    font-family: @text-font !important;
    font-size: @font-size-input !important;
    font-weight: @font-weight-normal !important;
    height: 100% !important;
    line-height: 1.5 !important;
    padding-left: 0 !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-search,
  .ant-select-selection-item {
    align-items: center;
    color: @placeholder-color !important;
    display: flex;
    font-family: @text-font !important;
    font-size: @font-size-input !important;
    font-weight: @font-weight-normal !important;
    height: 100%;
    line-height: 1.5 !important;
  }

  .ant-select-selection-item {
    color: @text-color !important;
  }

  .ant-select-arrow {
    &::after {
      color: @text-color;
      position: relative;
      .faicon(@fa-var-chevron-down, fas);
    }

    .anticon-down {
      display: none !important;

      svg {
        display: none !important;
      }
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      &::after {
        position: relative;
        .faicon(@fa-var-chevron-up, fas);
      }
    }
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;