@import "../../../Less/Mixins/_button-style.less";
@import "../../../Less/Mixins/_badge-style.less";

.theme-button {
  .button-style();

  > span,
  > a {
    display: block;
    line-height: 1;
  }

  &.ant-btn-default {
    .button-color-style(@grey-base);

    &.ant-btn-background-ghost {
      .button-ghost-style(@grey-base);

      color: @tertiary-dark !important;
    }
  }

  &.ant-btn-danger,
  &.ant-btn-dangerous {
    .button-color-style(@danger);
  }

  &.ant-btn-primary {
    .button-color-style(@primary);

    &.ant-btn-background-ghost {
      .button-ghost-style(@primary);
    }
  }

  &.ant-btn-secondary {
    .button-color-style(@secondary);

    &.ant-btn-background-ghost {
      .button-ghost-style(@secondary);
    }
  }

  &.ant-btn-tertiary {
    .button-color-style(@tertiary-dark);
  }

  &.ant-btn-cancel {
    .button-color-style(@grey-base);

    &.ant-btn-background-ghost {
      .button-ghost-style(@grey-base);
    }
  }

  &.ant-btn-danger {
    .button-color-style(@danger);

    &.ant-btn-background-ghost {
      .button-ghost-style(@danger);
    }
  }

  &.ant-btn-badge {
    .badge-style();

    background: @grey-dark !important;
    margin: 0 !important;
    margin-left: 1rem !important;
  }

  &.ml-auto {
    margin-left: auto !important;
  }

  &.mr-auto {
    margin-right: auto !important;
  }

  &.ant-btn-link {
    color: @secondary !important;
    font-family: @text-font !important;
    font-size: @font-size-stand !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &.ant-btn-square {
    border-radius: 0 !important;
    border-width: 0 !important;

    &.ant-btn-icon-only {
      min-width: unset !important;
    }
  }
  &.ant-btn-circle {
    border-radius: 50% !important;

    &.ant-btn-icon-only {
      background: @white !important;
      border-width: 1px !important;
      color: @text-color !important;
      height: 2rem !important;
      min-width: unset !important;
      padding: 1rem !important;
      width: 2rem;

      > .anticon {
        display: flex;
      }

      &.ant-btn-lg {
        height: 2.5rem !important;
        width: 2.5rem !important;
      }
    }
  }

  &.ant-btn-sm {
    padding: 0.5rem !important;
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;