@import "../../../Less/_brand-colors";
@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_alignfull";
@import "../../../Less/Mixins/_loan-state-colors";

.theme-loan-header {
  .alignfull();

  each(.loan-state-colors(), {
    &.loan-header__@{key} {

      .theme-loan-header-loan-state {
        background: @value;
      }
    }
  });

  background-color: @grey-base;
  display: flex;
  margin-bottom: @section-gap;
  margin-top: -@section-gap;
  position: relative;

  .theme-layout.anonymous & {
    margin-top: 0;
  }

  .theme-loan-header-loan-state {
    align-items: center;
    background-color: @primary;
    color: @white;
    display: flex;
    font-family: @heading-font;
    font-size: @font-size-xxs;
    justify-content: flex-end;
    padding-right: 3rem;
    text-transform: capitalize;
    width: 30vw;
  }

  @media @max480 {
    flex-direction: column;

    .theme-loan-header-loan-state {
      justify-content: flex-start;
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
      width: unset;
    }
  }

  .theme-loan-header-contents-wrapper {
    background-color: @grey-base;
    position: relative;
    width: 100%;

    &::after {
      background-color: rgba(0, 0, 0, 0.6);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .theme-loan-header-banner-image {
      background-color: @grey-base;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .theme-loan-header-contents {
      padding: @padding-panel;
      position: relative;
      z-index: 2;

      .ant-typography {
        color: @white !important;
      }

      .theme-badge-group {
        margin-bottom: 0.5rem;
      }
      .theme-currency-wrapper {
        font-family: @text-font !important;
        margin: 0;
      }
    }
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;