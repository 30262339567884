@import "../../../Less/_default-variables";
@import "../../../Less/_brand-colors";
@import "../../../Less/Mixins/_box-shadow-style";

@image-wrapper-size: 6.5rem;
@image-wrapper-size-sm: 5rem;

.theme-document-item {
  overflow: unset !important;

  .wrapper {
    padding: 0 @gap-medium !important;

    @media @max480 {
      padding: @gap-base !important;
    }
  }

  .panel-body {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .contents {
    cursor: pointer;
    display: flex;
    width: 100%;
  }

  .img-wrapper {
    align-items: center;
    background: white;
    display: flex;
    flex-basis: @image-wrapper-size;
    flex-direction: column;
    height: 8rem;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    top: 0;
    .box-shadow(default);

    .ant-image {
      margin: auto;

      img {
        max-width: 3rem;
        width: 3rem;
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex-basis: calc(100% - @image-wrapper-size);
    flex-direction: column;
    padding: @gap-medium @gap-big;

    > .theme-badge-group {
      margin-bottom: @gap-base;
    }

    > .theme-badge {
      margin-left: @gap-base !important;
      margin-top: 0 !important;
    }
  }

  .theme-document-button-control {
    margin-left: auto;
    z-index: 1;
  }

  .details-wrapper {
    display: inline-flex;
    flex-wrap: wrap;

    > span {
      line-height: 1.2;
    }
  }

  .theme-badge-group {
    flex-wrap: wrap;
  }
  .file-name {
    border-right: 2px solid @border-color;
    margin-bottom: @gap-sm;
    margin-right: @gap-base;
    padding-right: @gap-base;
  }

  .status-disable {
    margin-left: @gap-base !important;
    margin-top: 0 !important;
  }

  @media @max990 {
    .img-wrapper {
      flex-basis: @image-wrapper-size-sm;
      height: 5rem;
      padding: @gap-sm;

      .ant-image {
        img {
          margin: 0;
        }
      }

      .content-wrapper {
        flex-basis: calc(100% - @image-wrapper-size-sm);
      }
    }
  }

  @media @max480 {
    .contents {
      width: calc(100% - 3rem);
    }
    .img-wrapper {
      height: 4rem;
      position: absolute;
      right: @gap-base;
      top: 0;
      .ant-image {
        img {
          max-width: 2rem;
        }
      }
    }

    .content-wrapper {
      flex-basis: 100%;
      max-width: 100%;
      padding: 0;
    }

    .file-name {
      word-break: break-word;
    }
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;