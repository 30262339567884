@import "../../../Less/Mixins/_text-style";
@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_button-style";

.ant-popconfirm {
  max-width: 20rem;
  min-width: 20rem;

  .ant-popover-message-title {
    .text-style(heading);

    line-height: 1.3;
    padding-left: 0 !important;
    padding-top: 1.5rem !important;
  }

  .ant-popover-buttons {
    .ant-btn {
      border-color: @grey-base;
      border-width: 2px;
      color: @tertiary-dark;
      font-family: @text-font !important;
      font-size: @font-size-xs !important;
      height: auto !important;
      min-width: 3.5rem;
      padding: 0.3rem 1rem !important;

      @media @max480 {
        margin-bottom: 0.5rem;
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-within,
      &:focus-visible,
      &:target,
      &:active:focus {
        box-shadow: inset 0 0 0 0 transparent;
        text-decoration: none;
      }

      &.ant-btn-primary {
        background: transparent;
        border-color: @grey-base;
        color: @tertiary-dark;
      }
    }
  }
}

@primary-color: #EAB541;@primary: #EAB541;@secondary: #068DA7;@tertiary: #523458;@tertiary-dark: #27002D;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FFF0B6;@menu-color: #523458;@menu-active-color: #27002D;@heading-text-color: #523458;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #523458;@step-icon-active: #523458;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 1rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 1rem;@heading-font: Avenir LT Black, sans-serif;@text-font: Avenir LT Light, sans-serif;@label-font: Avenir LT Black, sans-serif;@step-title-font: Avenir LT Black, sans-serif;@button-font: Avenir LT Black, sans-serif;@small-font: Avenir LT Light, sans-serif;@menu-font: Avenir LT Black, sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;